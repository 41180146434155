<template>
  <div class="login-page vh-100 d-flex align-items-center justify-content-center">
    <div class="login-container">
      <particles id="particles-bg" :options="particlesOptions" />
      <div class="form-container">
        <div class="logo-section text-center mb-4">
          <img src="images/logo-white-shadow.svg" alt="Logo" class="logo-img mb-2">
          <h1 class="form-title">
            Ligne d'urgence
          </h1>
        </div>
        <form class="login-form" @submit.prevent="handleLogin">
          <b-form-group label="Nom d'utilisateur" label-for="username" class="custom-label">
            <b-form-input id="username" v-model="username" type="text" placeholder="Entrez votre nom d'utilisateur"
              required autofocus aria-describedby="username-help" />
          </b-form-group>
          <b-form-group label="Mot de passe" label-for="password" class="custom-label">
            <b-form-input id="password" v-model="password" type="password" placeholder="Entrez votre mot de passe"
              required />
          </b-form-group>
          <div v-if="errorMessage" class="error-message text-danger text-center mt-2">
            {{ errorMessage }}
          </div>
          <b-button type="submit" variant="primary" block class="mt-4">
            Connexion
          </b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import { Vue } from 'vue-property-decorator'
import Particles from 'particles.vue'
import axios from '@/libs/axiosIns'
import jwtDecode from 'jwt-decode'
import store from '@/store'
import {
  BFormInput,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'

Vue.use(Particles)

export default {
  name: 'Landing',
  components: {
    BFormInput,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      particlesOptions: {
        background: {
          color: '#fff',
          image: 'url(\'/images/refuge.jpg\')',
          position: 'bottom center',
          size: 'cover',
        },
        fpsLimit: 60,
        interactivity: {
          detectsOn: 'window',
          events: {
            onHover: {
              enable: true,
              mode: 'bubble',
              parallax: {
                enable: true,
                force: 140,
                smooth: 10,
              },
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 140,
              duration: 2,
              opacity: 0.8,
              size: 20,
            },
          },
        },
        particles: {
          color: {
            value: '#fff',
          },
          links: {
            enable: false,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: 'none',
            enable: true,
            outMode: 'out',
            random: true,
            speed: 0.7,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              value_area: 800,
            },
            value: 60,
          },
          opacity: {
            value: 0.4,
          },
          rotate: {
            random: {
              enable: true,
            },
            animation: {
              enable: true,
              speed: 3,
            },
            direction: 'random',
          },
          shape: {
            options: {
              image: [
                {
                  src: '/images/icon-white.svg',
                  height: 32,
                  width: 32,
                },
              ],
            },
            type: 'image',
          },
          size: {
            random: true,
            value: 10,
          },
        },
        detectRetina: true,
      },
      username: '',
      password: '',
      errorMessage: null,
    }
  },
  methods: {
    async handleLogin() {
      try {
        // Send login request to backend
        const response = await axios.post('/Auth/Login', {
          login: this.username,
          password: this.password,
        })

        // Assuming the token is returned as `access_token`
        const token = response.data.access_token
        if (token) {
          localStorage.setItem('token', token)

          const decodedToken = jwtDecode(token)
          const userId = decodedToken.sub

          store.commit('auth/setUserId', userId)
          store.commit('auth/setToken', token)
          axios.defaults.headers.common.Authorization = `Bearer ${token}`

          // Redirect user to a protected page (e.g., home page)
          this.$router.push({ name: 'planning' })
        }
      } catch (error) {
        // Check if there's a response from the server
        if (error.response && error.response.data) {
          const serverMessage = error.response.data.error // Assuming the API sends the error message under 'error'

          // Map server message to user-friendly error messages
          switch (serverMessage) {
            case 'Account is locked. Please try again later.':
            case 'Account is locked due to multiple failed login attempts.':
              this.errorMessage = 'Votre compte est verrouillé. Veuillez réessayer plus tard.'
              break
            case 'Invalid username or password.':
              this.errorMessage = "Nom d'utilisateur ou mot de passe incorrect."
              break
            default:
              this.errorMessage = "Une erreur inattendue s'est produite. Veuillez réessayer."
          }
        } else {
          this.errorMessage = "Une erreur réseau s'est produite. Veuillez vérifier votre connexion."
        }
      }
    },
  },
}
</script>

<style>
#particles-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  z-index: -10;
}

.login-page {
  color: white;
  overflow: hidden;
  position: relative;
}

.login-container {
  background-color: rgba(46, 46, 46, 0.75);
  padding: 2rem 3rem;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  max-width: 329px;
}

.logo-img {
  max-width: 180px;
}

.form-title {
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
}

.login-form {
  max-width: 400px;
  margin: 0 auto;
}

.error-message {
  font-size: 0.9rem;
  font-weight: 800;
}

.custom-label label {
  color: white !important;
}
</style>
